@import 'abstracts/variables', 'abstracts/functions', 'abstracts/mixins';
.feedback-form-fields {
  $block-name: &;

  //   #{$block-name} {
  //   }

  .swiper-wrapper {
    box-sizing: border-box;
  }

  &__fields {
  }

  &__congratulation {
  }

  &__congratulation-title {
    .title {
      @include mq($to: md) {
        font-size: 70px;
      }

      @include mq($to: sm) {
        font-size: 62px;
      }
    }
  }

  &__congratulation-description {
    margin-top: 30px;
    max-width: 400px;
  }

  &__congratulation-controls {
    display: flex;
    flex-wrap: nowrap;
    margin-top: 40px;

    .button {
      @include mq($to: md) {
        justify-content: center;
        width: 100%;
        max-width: 100%;
      }
    }

    @include mq($to: md) {
      display: block;
    }
  }

  &__congratulation-control {
    &:last-child {
      @include mq($to: md) {
        margin-top: 10px;
      }
    }
  }
}
